import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ICompanyApiService } from './company-api.interface';
import { Observable, map, take } from 'rxjs';
import { Company } from '../domain/Company.model';
import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { CompanyApiModel } from './models/company-api.model';
import { CompanyCreateOrUpdate } from '../domain/Company-create-or-update.model';

@Injectable({
  providedIn: 'root',
})
export class CompanyApiService implements ICompanyApiService {
  private _http = inject(HttpClient);
  private readonly BASE_URL = environment.baseUrl;

  private _setMapCompany(company: CompanyApiModel): Company {
    return {
      id: company.ID,
      name: company.name,
      createdAt: company.CreatedAt,
    };
  }

  private _setMapCompanies(companies: CompanyApiModel[]): Company[] {
    return companies.map((company) => {
      return {
        id: company.ID,
        name: company.name,
        createdAt: company.CreatedAt,
      };
    });
  }

  getCompanies(): Observable<Company[]> {
    const url = `${this.BASE_URL}companies`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return [];
        return this._setMapCompanies(response.results as CompanyApiModel[]);
      })
    );
  }

  getCompany(id: number): Observable<Company | null> {
    const url = `${this.BASE_URL}companies/${id}`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapCompany(response.results as CompanyApiModel);
      })
    );
  }

  createCompany(company: CompanyCreateOrUpdate): Observable<boolean> {
    const url = `${this.BASE_URL}companies`;
    const headers = new HttpHeaders();
    return this._http.post<ResponseApi>(url, company, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }

  updateCompany(
    id: number,
    company: CompanyCreateOrUpdate
  ): Observable<Company | null> {
    const url = `${this.BASE_URL}companies/${id}`;
    const headers = new HttpHeaders();
    return this._http.put<ResponseApi>(url, company, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.ok) return null;
        return this._setMapCompany(response.results as CompanyApiModel);
      })
    );
  }

  deleteCompany(id: number): Observable<boolean> {
    const url = `${this.BASE_URL}companies/${id}`;
    const headers = new HttpHeaders();
    return this._http.delete<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        return response.ok;
      })
    );
  }
}
